.tag-data {
  display: flex;
  justify-content: space-between;
}

.tag-colum ul {
  margin: 0;
  padding: 0;
}
.tag-colum ul li {
  width: 68px;
}

.tagging-link {
  color: #004887;
  font-weight: bold;
  text-decoration: none;
  width: 117px;
  margin: 0;
  font-size: 16px;
}
.tagging-link:hover {
  text-decoration: none;
  cursor: pointer;
}
.tagging-container {
  display: flex;
  padding: 5px;
  justify-content: center;
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -25px;
}

.tooltip .tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 15%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}
.toggle-table-data ul li {
  list-style: none;
  line-height: 2;
  padding: 0px 10px;
}
.toggle-table-data ul {
  margin: 0px;
}
.toggle-table-data ul li:nth-child(odd) {
  background-color: #f6f6f8;
}
.copy-feedback {
  margin-left: 1rem;
  visibility: hidden;
}

.copy-feedback.active {
  visibility: visible;
}
.alert-container {
  position: absolute;
  top: 5%;
  left: 50%;
  right: 50%;
  z-index: 999;
}

.alert-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 200px;
  height: 100px;
  z-index: 1;
  background-color: #ffffff;
  box-shadow: 1px 2px 10px -3px rgb(0 0 0 / 70%);
  -webkit-box-shadow: 1px 2px 10px -3px rgb(0 0 0 / 70%);
  -moz-box-shadow: 1px 2px 10px -3px rgb(0 0 0 / 70%);
}
.alert-inner h4 {
  margin: 0;
}

table thead th:nth-child(4) .cursor-pointer.select-none {
  width: 100px;
}
table thead th:nth-child(3) .cursor-pointer.select-none {
  width: 100px;
}
.refresh-timer {
  margin-left: 7px;
  cursor: pointer;
  vertical-align: middle;
}
.pds-pagination ul {
  height: 48px;
}
.input-num {
  width: 48px !important;
  height: 40px !important;
}
.pds-button-primary.reset {
  background-color: #c00000;
}
.pds-button-primary.reset:hover:not(:disabled) {
  background-color: #c00000;
}
.pds-button.pds-button-primary.search {
  margin-left: 5px;
  margin-right: 5px;
}
.pds-button.pds-button-secondary.clear {
  margin-left: 5px;
  margin-right: 5px;
}
.datepicker {
  width: 70%;
}
.datepicker input {
  height: 40px !important;
}
.btm-border-lite {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}
.pds-pagination-link {
  min-height: 40px !important;
  height: 40px !important;
}
.pagination-first-item {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.pagination-last-item {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.pds-pagination [data-feather] {
  height: 0 !important;
  width: 0 !important;
}
