.toggle-table-data {
  padding: 0px !important;
}
.toggle-table-data ul li {
  list-style: none;
  line-height: 2;
  padding: 0px 10px;
}
.toggle-table-data ul {
  margin: 0px;
  padding: 0px !important;
}
.pds-pagination ul {
  height: 48px;
}
.input-num {
  width: 48px !important;
  height: 40px !important;
}
.pds-button-primary.reset {
  background-color: #c00000;
}
.pds-button-primary.reset:hover:not(:disabled) {
  background-color: #c00000;
}
.datepicker {
  width: 70%;
}
.datepicker input {
  height: 40px !important;
}
.pds-pagination-link {
  min-height: 40px !important;
  height: 40px !important;
}
.pagination-first-item {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.pagination-last-item {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.pds-pagination [data-feather] {
  height: 0 !important;
  width: 0 !important;
}
