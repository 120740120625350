.tag-data{
  display: flex;
  justify-content: space-between;
 
}

.tooltip {
position: relative;
display: inline-block;

}

.tooltip .tooltip-text {
visibility: hidden;
width: 120px;
background-color: black;
color: #fff;
text-align: center;
border-radius: 6px;
padding: 5px 0;
position: absolute;
z-index: 1;
bottom: 100%;
left: 50%;
margin-left: -25px;
}

.tooltip .tooltip-text::after {
content: "";
position: absolute;
top: 100%;
left: 15%;
margin-left: -5px;
border-width: 5px;
border-style: solid;
border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltip-text {
visibility: visible;
}
.toggle-table-data ul li{
list-style: none;
line-height: 2;
padding: 0px 10px;
}
.toggle-table-data ul{
margin: 0px;
}
.toggle-table-data ul li:nth-child(odd) {
background-color: #f6f6f8;

}
.copy-feedback {
margin-left: 1rem;
visibility: hidden;
}

.copy-feedback.active {
visibility: visible;
}

.alert-inner {
display: flex;
align-items: center;
justify-content: center;
padding: 20px;
border-radius: 10px;
text-align: center;
width: 200px;
height: 100px;
z-index: 1;
background-color: #ffffff;
box-shadow: 1px 2px 10px -3px rgb(0 0 0 / 70%);
-webkit-box-shadow: 1px 2px 10px -3px rgb(0 0 0 / 70%);
-moz-box-shadow: 1px 2px 10px -3px rgb(0 0 0 / 70%);
}
.alert-inner h4{
margin: 0;
}

table thead th:nth-child(4) .cursor-pointer.select-none {
  width: 100px;
}
table thead th:nth-child(3) .cursor-pointer.select-none {
  width: 100px;
}
.action-data{
  display: flex;
  flex-direction: row;
  white-space: nowrap;
}
.action-data span{
  font-weight: bold;
  color: #004887;
  font-size: 16px;
  cursor: pointer;
}
.pds-pagination ul {
  height: 48px;
}
.input-num{
  width: 48px !important;
  height: 30px !important;
}
