table {
  width: 100%;
}

thead > tr > th > span > input {
  color: black !important;
}

tfoot {
  color: gray;
}

tfoot th {
  font-weight: normal;
}

.nav-btn {
  height: 7vh;
}

select {
  color: #035fa4;
}
