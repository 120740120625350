.form-control {
  padding: 0.375rem 0.75rem;
  border: 1px solid #000;
  border-radius: 0.45rem;
}

.form-control:focus {
  color: #495057;
  border-color: #8e93e5;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 4, 255, 0.15);
}
.form-group label {
  text-align: right;
  display: inline-block;
  width: 180px;
  margin: 0.5% 1% 0 0;
  padding-right: 25px;
  font-weight: bold;
}
table td {
  padding: 8px;
  line-height: 1.428571429;
  vertical-align: top;
}
.pds-select svg {
  right: 25px !important;
  top: -7px !important;
}
.down-arrow-process {
  position: absolute;
  right: 8px;
  top: 3px;
}
.pds-button-primary.cancel {
  background-color: #c00000;
}
