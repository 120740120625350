* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  min-height: 100%;
}
.Content {
  min-height: calc(100vh - 181px);
  padding: 15px 0px;
  margin-bottom: 100px;
}

.footer-container {
  text-align: center;
  background-color: #035fa4;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
}
.popup-container .popup .close-btn {
  padding: 10px 15px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  outline: none;
  background: crimson;
  color: white;
  cursor: pointer;
}

.popup-container {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9;
}
.popup-container .popup {
  z-index: 9;
  padding: 20px;
  width: 800px;
  min-height: 600px;
  background-color: white;
  /* display: flex;
align-items: center;
justify-content: center; */
  border-radius: 5px;
  position: relative;
  animation: animate 0.3;
}
.popup .close-btn {
  position: absolute;
  top: 7px;
  right: 20px;
  padding: 8px 11px;
  background: crimson;
  color: white;
  font-weight: bold;
  border: none;
  outline: none;
  border-radius: 2px;
  cursor: pointer;
}
/* .popup .save-btn{
  position: absolute;
  bottom: 7px;
  left: 20px;
  padding: 10px 15px;
  background: rgb(8, 48, 148); 
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
}
.popup .cancel-btn{
  position: absolute;
  bottom: 7px;
  right: 20px;
  padding: 10px 15px;
  background: rgb(8, 48, 148); 
  color: white;
  font-weight: bold;
  border: none;
  outline: none;
  border-radius: 10px;
  cursor: pointer;
} */
.table-container {
  padding: 20px 0px;
}
.tagging-link {
  /* color: #004887;
  font-weight: bold; */
  line-height: 16px;
}
.tagging-link:hover {
  text-decoration: none;
  cursor: pointer;
}
.tagging-textarea {
  width: 100%;
  margin-top: 16px;
  padding: 5px;
}
.paginationTable {
  display: flex;
  justify-content: space-between;
}
.pds-select svg {
  right: 25px !important;
  top: -7px !important;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgba(51, 51, 51, 0.9) !important;
  background-color: var(--color-rgba-neutral-1-90) !important;
}

.loading-container {
  height: calc(100vh - 300px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* @keyframes animate {
  from{
    transform:  scale(0.5);
  }
  to {
    transform: scale(1);
  }
} */
.pds-button {
  height: 40px;
}
